(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('LandingPageHAQEvaluationController', LandingPageHAQEvaluationController);

    LandingPageHAQEvaluationController.$inject = ['$scope', '$state', 'evaluationEntity', 'SurveyResultService', 'HAQEvaluationWorkflowService', 'DataUtils', 'ClearLocalStorageService', '$uibModal', '$window'];

    function LandingPageHAQEvaluationController($scope, $state, evaluationEntity, SurveyResultService, HAQEvaluationWorkflowService, DataUtils, ClearLocalStorageService, $uibModal, $window) {
        var vm = this;
        vm.evaluationEntity = evaluationEntity;
        vm.downloadResultPdf = downloadResultPdf;
        vm.downloadResponsePdf = downloadResponsePdf;
        vm.timerSeconds = vm.evaluationEntity.timeSpent ? vm.evaluationEntity.timeSpent : 0;
        vm.timerTextClass = timerTextClass;
        vm.isStopTimer = isStopTimer;
        vm.resetTimeLogin = resetTimeLogin;
        vm.isResume = false;
        vm.isLoading = false;

        vm.typeScores = {
            highScores: 'HIGH_SCORES'
        };

        init();

        function init() {
            resetTimeLogin();
            ClearLocalStorageService.clearTimeSpent();
            if (!vm.evaluationEntity.haqTest) {
                vm.noHaveHaqReadyForReview = true;
                return;
            }
            if (vm.evaluationEntity.status === 'UNDER_REVIEW' && (Object.entries(vm.evaluationEntity.answers).length > 0 || vm.evaluationEntity.timeSpent > 0)) {
                vm.isResume = true;
            }

            // if (vm.evaluationEntity.mainQuestion.lastQuestion) {
            //     $state.go('haq-evaluation.submit');
            //     return;
            // }
        }

        vm.start = function () {
            if (vm.isLoading || !vm.evaluationEntity.wholeBatteries) {
                return;
            }
            if (vm.evaluationEntity && vm.evaluationEntity.haqEvaluationId) {
                if (!vm.isResume) { // start HAQ evaluation
                    HAQEvaluationWorkflowService.startHaqEvaluation(vm.evaluationEntity.haqEvaluationId).then(function (res) {
                        ClearLocalStorageService.setTimeSpent();
                        var timestamp = new Date().getTime();
                        $state.go('haq-evaluation.workflow', { timestamp: timestamp });
                        return;
                    });
                } else {
                    HAQEvaluationWorkflowService.updateTimeSpent(vm.evaluationEntity.haqEvaluationId, false, false, false).then(function (res) {
                        ClearLocalStorageService.setTimeSpent();
                        var timestamp = new Date().getTime();
                        $state.go('haq-evaluation.workflow', { timestamp: timestamp });
                        return;
                    });
                }
            }
        };

        vm.skipReviewEvaluation = function () {
            if (!vm.evaluationEntity.haqEvaluationId) {
                return;
            }
            vm.isLoading = true;
            $uibModal.open({
                templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/skip-evaluation/skip-evaluation-dialog.html',
                controller: 'skipEvaluationDialogController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message confirm-dialog',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            haqEvaluationId: vm.evaluationEntity.haqEvaluationId,
                            reason: null
                        };
                    }
                }
            }).result.then(function () {
                $state.go('home');
                vm.isLoading = false;
            }, function () {
                vm.isLoading = false;
            });
        };

        function setIsResume(resume) {
            vm.isResume = resume ? resume : false;
        }

        function resetTimeLogin() {
            if (vm.evaluationEntity && vm.evaluationEntity.haqEvaluationId) {
                vm.isLoading = true;
                $window.setTimeout(function () {
                    HAQEvaluationWorkflowService.onlyResetTimeSpentLogin(vm.evaluationEntity.haqEvaluationId).then(function (res) {
                        vm.timerSeconds = res ? res : 0;
                        vm.isLoading = false;
                    });
                }, 300); // Set a timeout to get the most recent time spent after workflow destroy updateTimeSpent
            }
        }

        function isStopTimer() {
            // var timeSpent = ClearLocalStorageService.getTimeSpent();
            // return vm.isResume || !timeSpent;
            return true;
        }

        function timerTextClass() {
            return DataUtils.timerTextClass(vm.timerSeconds);
        }

        function downloadResultPdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResult(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        function downloadResponsePdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResponse(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }
    }
})();
