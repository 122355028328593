(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('SurveyResultService', SurveyResultService);

    SurveyResultService.$inject = ['$q', 'halClient', '$http', 'DateUtils', 'DownloadBlobService'];

    function SurveyResultService ($q, halClient, $http, DateUtils, DownloadBlobService) {
    	var baseUrl = 'api';

        var service = {
        	'searchSurveyResults': searchSurveyResults,
        	'getSurveyResult': getSurveyResult,
        	'getSupportedDataList': getSupportedDataList,
        	'changeDisposition': changeDisposition,
        	'findByPatient': findByPatient,
        	'findByPatientPaganition': findByPatientPaganition,
        	'getCurrentSurveyResult': getCurrentSurveyResult,
            'downloadResult': downloadResult,
            'downloadResponse': downloadResponse,
            'downloadHAQResultsReports': downloadHAQResultsReports,
            'savePatientCaseSelected': savePatientCaseSelected
        };

        function getCurrentSurveyResult(noWait, surveyResultId) {
			if (!surveyResultId) {
				surveyResultId = 0;
			}
        	var deferred = $q.defer();
        	var url = baseUrl + '/survey-results/get-current-survey-result?noWait=' + noWait + '&surveyResultId=' + surveyResultId;

        	halClient.$get(url).then(function success(result) {
        		deferred.resolve(result);
    		}, function error(e) {
    			deferred.reject(error);
    		});

        	return deferred.promise;
        }

        function findByPatient(patientId) {
        	var deferred = $q.defer();
        	var url = baseUrl + '/survey-results/find-by-patient?patientId=' + patientId;

        	halClient.$get(url).then(function success(result) {
        		deferred.resolve(result);
    		}, function error(e) {
    			deferred.reject(error);
    		});

        	return deferred.promise;
        }

        function findByPatientPaganition(patientId, pageable) {
        	var deferred = $q.defer();
        	var url = baseUrl + '/survey-results/find-by-patient';

        	$http.get(url, {
				params: {
					patientId: patientId,
					page: pageable.page,
					size: pageable.size,
					sort: pageable.sort
				}
			})
			.success(function(data) {
                if (data && data.lastModifiedDate) {
                    data.lastModifiedDate = DateUtils.convertLocalDateFromServer(data.lastModifiedDate);
                }
				deferred.resolve(data);
			})
			.error(function(e) {
				deferred.reject(e);
			});

			return deferred.promise;
        }

        function getSurveyResult(id) {
        	var deferred = $q.defer();
        	var url = baseUrl + '/survey-results/' + id;
        	halClient.$get(url).then(function success(result) {
        		deferred.resolve(result);
    		}, function error(e) {
    			deferred.reject(error);
    		});

        	return deferred.promise;
        }

        function getSupportedDataList() {
    		var deferred = $q.defer();

    		var url = baseUrl + '/survey-results/supported-data-list';

    		$http.get(url).success(function(data) {
    			deferred.resolve(data);
    		})
    		.error(function(error) {
    			deferred.reject(error);
    		});

    		return deferred.promise;
    	};

        function searchSurveyResults(query, status, pageable) {
    		var deferred = $q.defer();

        	var url = baseUrl + '/survey-results/search';
        	$http.get(url, {
				params: {
					query: ((query != null) ? encodeURIComponent(query) : null),
					status: status,
					page: pageable.page,
					size: pageable.size,
					sort: pageable.sort
				}
			})
			.success(function(data) {
				deferred.resolve(data);
			})
			.error(function(e) {
				deferred.reject(e);
			});

			return deferred.promise;
        }

        function changeDisposition(id, disposition) {
        	var params = {id: id, disposition: disposition};
        	var url = baseUrl + '/survey-results/change-disposition';
        	halClient.$post(url, null, params).then(function success(result) {
        		return result;
    		}, function error(e) {
    			return e;
    		});
        }

        function downloadResult(id) {
            var deferred = $q.defer();
            var url = baseUrl + '/survey-results/download/' + id;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
					saveAs(blob, "New Patient Questionnaire Result.pdf");
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function downloadResponse(id) {
            var deferred = $q.defer();
            var url = baseUrl + '/survey-results/download-response/' + id;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
					saveAs(blob, "New Patient Questionnaire Response.pdf");
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function downloadHAQResultsReports(fromDate, toDate, isAdHoc, fileDownloadId) {
            var deferred = $q.defer();
            var url = baseUrl + '/survey-results/download-haq-results-reports/' + fileDownloadId;
            $http({
                url: url,
                method: "GET",
                params: {isAdHoc: isAdHoc, fromDate: fromDate, toDate: toDate},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                var statusDownload = headers('status-download');
                if (status === 204) {
                    deferred.resolve({status: status, statusDownload: statusDownload});
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve({status: status, statusDownload: statusDownload});
                }
            }).error(function (data, status, headers, config) {
                var statusDownload = headers('status-download');
                deferred.resolve({status: status, statusDownload: statusDownload});
            });
            return deferred.promise;
        }

        function savePatientCaseSelected(surveyResultId, data) {
            var url = baseUrl + '/survey-results/save-patient-case-selected/' + surveyResultId;
            return $http.post(url, data).then(function (response) {
                return response;
            });
        }

        return service;
    };
})();
