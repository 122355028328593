(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('RequestHAQResultsReportsController', RequestHAQResultsReportsController);

    RequestHAQResultsReportsController.$inject = ['FileDownloadService', '$stateParams', '$state'];

    function RequestHAQResultsReportsController (FileDownloadService, $stateParams, $state) {
        var vm = this;
        vm.download = download;
        vm.noPdf = false;
        vm.secretKey = null;
        vm.validDownload = false;
        vm.isProcessing = false;
        init();

        function init() {
            vm.secretKey = $stateParams.secretKey;
            FileDownloadService.getFileDownloadBySecretKey(vm.secretKey).then(function (response) {
                if (response && response.status === 200) {
                    vm.validDownload = true;
                }
            }, function error(e) {
                if (e && e.status === 404) {
                    $state.go('accessdenied');
                } else { // BAD_REQUEST
                    $state.go('error');
                }
            });
        }

        function download() {
            if (vm.validDownload) {
                vm.isProcessing = true;
                FileDownloadService.downloadReport(vm.secretKey).then(function (res) {
                    vm.isProcessing = false;
                    if (res === 204) {
                        vm.noPdf = true;
                    }
                });
            }
        }
    }
})();
