(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HAQEvaluationWorkflowController', HAQEvaluationWorkflowController);

    HAQEvaluationWorkflowController.$inject = ['$scope', '$state', 'evaluationEntity', 'HAQEvaluationWorkflowService', '$timeout', 'evaluationStorage', 'SurveyResultService', 'DataUtils', 'ClearLocalStorageService', 'timerHasElapsed', 'ipmConstants', 'timeSpent', 'Principal', 'WebWorkerEvlService', '$uibModal'];

    function HAQEvaluationWorkflowController($scope, $state, evaluationEntity, HAQEvaluationWorkflowService, $timeout, evaluationStorage, SurveyResultService, DataUtils, ClearLocalStorageService, timerHasElapsed, constants, timeSpent, Principal, WebWorkerEvlService, $uibModal ) {
        var vm = this;
        vm.evaluationEntity = evaluationEntity;
        vm.haqTest = vm.evaluationEntity.haqTest;
        vm.mainQuestion = vm.evaluationEntity.mainQuestion;
        vm.findQuestion = findQuestion;
        vm.sumitAnswer = sumitAnswer;
        vm.removeOldAnswer = removeOldAnswer;
        vm.storeCurrentAnswerIntoStorage = storeCurrentAnswerIntoStorage;
        vm.findAndUpdateOldChildAnswer = findAndUpdateOldChildAnswer;
        vm.init = init;
        vm.downloadResultPdf = downloadResultPdf;
        vm.downloadResponsePdf = downloadResponsePdf;
        vm.removeChildsNewAnswersByCode = removeChildsNewAnswersByCode;
        vm.timerSeconds = timeSpent ? timeSpent : 0;
        vm.timerTextClass = timerTextClass;

        vm.timerHasElapsed = timerHasElapsed && timerHasElapsed !== 0 ? timerHasElapsed : constants.TIME_ELAPSED_DEFAULT; // default 31 minutes # get timerHasElapsed from config
        vm.isSaving = false;
        vm.isSavingTimeSpent = false;

        vm.firstQuestionCode = 'Q-1';
        vm.lastQuestionCode = 'Q-5';

        function init() {
            initWorkerService();
            if (vm.timerHasElapsed && !ClearLocalStorageService.getTimerHasElapsed()) {
                ClearLocalStorageService.setTimerHasElapsed(vm.timerHasElapsed);
            }
            if (vm.mainQuestion) {
                ClearLocalStorageService.clearActionEvaluationWorkflow();
                ClearLocalStorageService.setQuestionIdEvaluationWorkflow(vm.mainQuestion.id);
                // update TimeSpent when reload, back or next.
                refreshTimeSpent();
                // init list value to submit server
                vm.newAnswersSubmitServer = new Map();
                // init answers list
                vm.answers = new Map(Object.entries(vm.evaluationEntity.answers));
                // push list temp answer into list answer and list submit
                vm.evaluationStorage = evaluationStorage;
                var tempAnswerMap = vm.evaluationStorage.get(vm.mainQuestion.code);
                if (tempAnswerMap) {
                    tempAnswerMap.forEach(function(value, key) {
                        vm.answers.set(key, value);
                        vm.newAnswersSubmitServer.set(key, value);
                        // update old value answer value to new value store in evaluation storage
                        vm.findAndUpdateOldChildAnswer(vm.mainQuestion, tempAnswerMap);
                    });
                }
                // [start] block code for type [EPWORTH] and [ISI] remove relate question answers
                if (vm.mainQuestion.code === 'Q-42') {
                    vm.specialValue = vm.answers.get('Q-42');
                } else if (vm.mainQuestion.code === 'Q-64') {
                    vm.specialValue = vm.answers.get('Q-64');
                }
                // [end] block code for type [EPWORTH] and [ISI] remove relate question answers
            }
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }

        function initWorkerService() {
            WebWorkerEvlService.startTimeSpent();
            WebWorkerEvlService.startIntervalAutoSave();
            if (WebWorkerEvlService.getWebWorker()) {
                WebWorkerEvlService.getWebWorker().onmessage = function (event) {
                    if (event.data && event.data === constants.WEB_WORKER.START_TIME_SPENT_INTERVAL_EVL) {
                        timeSpentChangeW();
                    } else if (event.data === constants.WEB_WORKER.START_AUTO_SAVE_INTERVAL_EVL) {
                        startAutoSaveTimeSpentW();
                    }
                };
                WebWorkerEvlService.getWebWorker().addEventListener('error', function (event) {
                    // console.error('WebWorkerEvlService is offline');
                    openErrorClearCacheDialog();
                });
            } else {
                // console.error('WebWorkerEvlService is not available');
                openErrorClearCacheDialog();
            }
        }

        function timeSpentChangeW() {
            vm.timerSeconds += 1;
            $scope.$digest();
            // console.log('timerSeconds', vm.timerSeconds, elapsedTime);
        }

        function startAutoSaveTimeSpentW() {
            updateTimeSpent(false, false);
        }

        function handleVisibilityChange() {
            if (document.hidden) {
                console.log('handleVisibilityChange - the browser window or tab is minimized');
            } else {
                console.log('handleVisibilityChange - the browser window or tab is resume');
            }
            startAutoSaveTimeSpentW();
        }

        function findAndUpdateOldChildAnswer (question, tempMapAnswer) {
            if (vm.answers.get(question.code)) {
                if (tempMapAnswer.get(question.code)) {
                    vm.answers.set(question.code, tempMapAnswer.get(question.code))
                } else {
                    vm.answers.delete(question.code);
                }
            }
            // continue to find childs in tree
            _.forEach(question.childs, function (a) {
                if (vm.answers.get(a.code)) {
                    vm.answers.set(a.code, tempMapAnswer.get(a.code));
                    vm.findAndUpdateOldChildAnswer(a, tempMapAnswer);
                }
            });
        }

        function navigationBatteryTestEvaluationWorkflow(haqEvaluation, evaluationAction) {
            var evaluationRequest = {
                haqEvaluation: haqEvaluation.haqEvaluationId,
                evaluationAction: evaluationAction
            };
            ClearLocalStorageService.setActionEvaluationWorkflow(evaluationAction); // to resume question when next, previous battery test
            HAQEvaluationWorkflowService.navigationBatteryTestEvaluationWorkflow(evaluationRequest).then(function (res) {
                if (res) {
                    $state.go('haq-evaluation.workflow', {timestamp: new Date().getTime()});
                    return;
                }
            });
        }

        function getSubmitLastQuestion(isNextAction) {
            return isNextAction && vm.evaluationEntity.navigationHaqEvaluation && vm.evaluationEntity.navigationHaqEvaluation.lastBatteryTest && vm.evaluationEntity.mainQuestion.lastQuestion;
        }

        function sumitAnswer(isNextAction) {
            vm.isSaving = true;
            var convertMap = Object.fromEntries(vm.newAnswersSubmitServer);

            var evaluationRequest = {
                haqEvaluation: vm.evaluationEntity.haqEvaluationId,
                questionId: vm.evaluationEntity.mainQuestion.id,
                haqTestId: vm.evaluationEntity.haqTest.id,
                values: isNextAction ? JSON.stringify(convertMap) : "{}",
                nextAction: isNextAction,
                submitLastQuestion: getSubmitLastQuestion(isNextAction)
            };

            HAQEvaluationWorkflowService.sumbitEvaluation(evaluationRequest).then(function(res) {
                // console.log('res', res.navigationHaqEvaluation, isNextAction);
                if (isNextAction && res && vm.evaluationEntity.navigationHaqEvaluation && vm.evaluationEntity.navigationHaqEvaluation.navigationNext && vm.evaluationEntity.navigationHaqEvaluation.nextBatteryTestId) { // next click
                    navigationBatteryTestEvaluationWorkflow(vm.evaluationEntity, 'NEXT_BATTERY_TEST');
                    return;
                } else if (!isNextAction && res && vm.evaluationEntity.navigationHaqEvaluation && vm.evaluationEntity.navigationHaqEvaluation.navigationPrevious && vm.evaluationEntity.navigationHaqEvaluation.previousBatteryTestId) { // previous click
                    navigationBatteryTestEvaluationWorkflow(vm.evaluationEntity, 'PREVIOUS_BATTERY_TEST');
                    return;
                }
                if (isNextAction) {
                    // go to submit page
                    if (vm.evaluationEntity.navigationHaqEvaluation && vm.evaluationEntity.navigationHaqEvaluation.lastBatteryTest && vm.evaluationEntity.mainQuestion.lastQuestion) {
                        $state.go('haq-evaluation.submit');
                        return;
                    }
                    // remove answer storage if submit already into server
                    vm.evaluationStorage.delete(vm.mainQuestion.code);

                    // [start] handle remove special case code type [EPWORTH] and [ISI]
                    if (vm.mainQuestion.code === 'Q-42' && vm.specialValue !== vm.answers.get('Q-42')) {
                        vm.evaluationStorage.delete('Q-43');
                        vm.evaluationStorage.delete('Q-44');
                        vm.evaluationStorage.delete('Q-45');
                        vm.evaluationStorage.delete('Q-48');
                    }
                    if (vm.mainQuestion.code === 'Q-64' && vm.specialValue !== vm.answers.get('Q-64')) {
                        vm.evaluationStorage.delete('Q-65');
                        vm.evaluationStorage.delete('Q-66');
                        vm.evaluationStorage.delete('Q-67');
                        vm.evaluationStorage.delete('Q-70');
                    }
                    // [end] handle remove special case code type [EPWORTH] and [ISI]
                }
                vm.mainQuestion = null;
                vm.isSaving = false;
                $timeout(function(){
                    // re-init
                    vm.evaluationEntity = res;
                    vm.mainQuestion = vm.evaluationEntity.mainQuestion;
                    vm.haqTest = vm.evaluationEntity.haqTest;
                    vm.init();
                }, 10);

            }, function errorSave(error) {
                onSaveError(error);
            });
        }

        function onSaveError(error) {
            if (error.status === 400) {
                // remove answer storage if submit already into server
                vm.evaluationStorage.delete(vm.mainQuestion.code);
                $state.go('home');
            }
        }

        function isFirstQuestionOfTest() {
            return vm.evaluationEntity.mainQuestion && vm.evaluationEntity.mainQuestion.firstQuestion;
        }

        vm.checkQuestionSkipCommon = function (evaluationTestScores) {
            var qhpQuestionsSkipped = evaluationTestScores.qhpQuestionsSkipped;
            var typeScore = evaluationTestScores.typeScore;
            var type = evaluationTestScores.type;
            if (typeScore !== 'HIGH_SCORES') {
                return false;
            }
            if ((vm.mainQuestion && (vm.mainQuestion.code === vm.firstQuestionCode
                || vm.mainQuestion.code === vm.lastQuestionCode))
                || vm.evaluationEntity.questionTypeWorkflow === type) {
                return true;
            }
            if (!qhpQuestionsSkipped || qhpQuestionsSkipped.length === 0) {
                return false;
            }
            return !_.isEmpty(_.filter(qhpQuestionsSkipped, function (qhpQuestion) {
                return vm.mainQuestion && qhpQuestion && qhpQuestion.qhpQuestionGetDataId === vm.mainQuestion.id;
            }));
        };

        vm.previous = function() {
            if (vm.isDisablePreviousButton()) {
                return;
            }
            vm.storeCurrentAnswerIntoStorage();
            if (!vm.isSaving) {
                vm.sumitAnswer(false);
            }
            clearIntervalWorker();
        };

        vm.next = function(isFormInvalid) {
            if (isFormInvalid || vm.isDisableNextButton()) {
                return;
            }
            if (!isValidTimeSpent()) {
                return;
            }

            if (!vm.isSaving) {
                vm.sumitAnswer(true);
            }
            clearIntervalWorker();
        };

        vm.isDisablePreviousButton = function() {
            return isFirstQuestionOfTest() && vm.evaluationEntity.navigationHaqEvaluation && vm.evaluationEntity.navigationHaqEvaluation.firstBatteryTest ;
        }

        vm.isShowTimeSpentErrorMessage = function() {
            return vm.evaluationEntity.mainQuestion.lastQuestion && vm.isDisableNextButton();
        }

        vm.isDisableNextButton = function() {
            if (!isValidTimeSpent()) {
                return true;
            }
            return false;
        }

        function isValidTimeSpent() {
            if (vm.evaluationEntity.mainQuestion.lastQuestion) {
                var timerMinutes = vm.timerSeconds ? parseInt(vm.timerSeconds / 60) : 0;
                return timerMinutes >= vm.timerHasElapsed;
            }
            return true;
        }

        vm.handleQuestionAnswerChange = function(data) {
            if (data.clearChilds) {
                // find parent question to clear childs answer and put parent answer into map
                var parentQuestion = vm.findQuestion(vm.mainQuestion, data.question.code);
                if (parentQuestion) {
                    vm.removeChildsNewAnswersByCode(parentQuestion);
                }
                // remove old childs answer value
                vm.removeOldAnswer(data.question.code);
            }
            vm.newAnswersSubmitServer.set(data.question.code, data.value);
            // update value for this code
            vm.answers.set(data.question.code, data.value);
        }

        function removeChildsNewAnswersByCode(parentQuestion) {
            if (parentQuestion) {
                _.forEach(parentQuestion.childs, function(c) {
                    vm.removeChildsNewAnswersByCode(c);
                })
                vm.newAnswersSubmitServer.delete(parentQuestion.code); // clear childs answers
            }
        }

        /**
         * This function Description:
         * - when back-previous question
         * - not save into server
         * - we hold data in state storage
         **/
        function storeCurrentAnswerIntoStorage() {
            vm.evaluationStorage.set(vm.mainQuestion.code, vm.newAnswersSubmitServer);
        }

        /**
         * This function Description:
         * - find question in tree
         **/
        function findQuestion(question, code) {
            var parentQuestion = question.code === code ? question : undefined;
            if (parentQuestion) {
                return parentQuestion;
            } else {
                _.forEach(question.childs, function(c) {
                    var a = vm.findQuestion(c, code);
                    if (a) {
                        parentQuestion = a;
                    }
                })
            }
            return parentQuestion;
        }

        /**
         * This function Description:
         * - when back-previous question
         * - change parent answer
         * - we will clear remove child answer in object vm.answers
         **/
        function removeOldAnswer(code) {
            var question = vm.findQuestion(vm.mainQuestion, code);
            if (question) {
                _.forEach(question.childs, function(c) {
                    vm.removeOldAnswer(c.code);
                    vm.answers.delete(c.code); // clear childs vm.answers to compare
                });
            }
            vm.answers.delete(code);
        }

        function downloadResultPdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResult(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        function timerTextClass() {
            return DataUtils.timerTextClass(vm.timerSeconds);
        }

        function downloadResponsePdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResponse(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        function refreshTimeSpent() {
            setTimeout(function () {
                updateTimeSpent(true, false);
            }, 500);
        }

        function updateTimeSpent(isRefresh, isReset) {
            if (vm.isSavingTimeSpent === true) {
                return;
            }
            isRefresh = isRefresh ? true : false;
            isReset = isReset ? true : false;
            vm.isSavingTimeSpent = true;

            HAQEvaluationWorkflowService.updateTimeSpent(vm.evaluationEntity.haqEvaluationId, true, isReset, isRefresh).then(function (res) {
                if (res && res !== vm.timerSeconds) {
                    vm.timerSeconds = res;
                }
                vm.isSavingTimeSpent = false;
            }, function error(e) {
                vm.isSavingTimeSpent = false;
            });
        }

        function clearWorkerService() {
            WebWorkerEvlService.close();
        }

        function clearIntervalWorker() {
            WebWorkerEvlService.clearTimeSpent();
            WebWorkerEvlService.clearIntervalAutoSave();
            // console.log('clearIntervalWorker', new Date());
        }

        function goBack() {
            $state.go('home');
            return;
        }

        function openErrorClearCacheDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.qhpWorkflow.error.clearCache.message"},
                    title: function () {return "ipmApp.qhpWorkflow.error.title"}
                }
            }).result.then(function() {
                goBack();
            }, function() {
            });
        }

        vm.init();

        $scope.$on('$destroy', function() {
            clearIntervalWorker();
            clearWorkerService();
            ClearLocalStorageService.clearQuestionIdEvaluationWorkflow();
            ClearLocalStorageService.clearActionEvaluationWorkflow();
            // eslint-disable-next-line angular/document-service
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            Principal.identity(false).then(function (account) {
                if (account) {
                    updateTimeSpent(false, true); // reset timeSpentLogin
                }
            });
        });

    }
})();
