(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadHAQResultsReportsController', DownloadHAQResultsReportsController);

    DownloadHAQResultsReportsController.$inject = ['$state', '$filter', '$stateParams', '$window', 'SurveyResultService', 'DateUtils', 'isAdhoc', '$uibModal', '$interval', '$scope', 'ClearLocalStorageService', 'FileDownloadService', '$uibModalStack'];

    function DownloadHAQResultsReportsController ($state, $filter, $stateParams, $window, SurveyResultService, DateUtils, isAdhoc, $uibModal, $interval, $scope, ClearLocalStorageService, FileDownloadService, $uibModalStack) {
        var vm = this;
        var intervalLimitTimeDownload = null;
        var intervalCheckStatusDownload = null;
        vm.download = download;
        vm.checkProcessingDownload = checkProcessingDownload;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;
        vm.isDownloadingHasSentMail = false;
        vm.inValidDates = false;
        vm.isAdhoc = isAdhoc;
        vm.isProcessing = false;
        vm.isProcessingDownload = false;
        vm.limitTimeMillisSecondsDownload = 1000*60*2; // 120 seconds
        vm.limitTimeRequestCheckStatus = 1000 * 30; // 30 seconds
        vm.limitTimeStart = null;
        vm.hasOpenningDialogRequest = false;
        vm.sessionDownloadId = null;
        vm.account = null;
        vm.fileDownload = null;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        init();

        function init() {
            // set to Monday of this week
        	vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
        	// set to previous Monday
        	vm.fromDate.setDate(vm.fromDate.getDate() - 7);

        	// set to previous Friday
        	vm.toDate = new Date(vm.fromDate);
        	vm.toDate.setDate(vm.toDate.getDate() + 4);
        }

        function setSessionDownload() {
            vm.sessionDownloadId = new Date().getTime().toString(); // sessionDownloadId = account.id + '_' + timestamp. account.id will add after submit download
        }

        function createFileDownload(fromDate, toDate, sessionDownloadId) {
            vm.fileDownload = {
                fromDate: fromDate,
                toDate: toDate,
                sessionDownloadId: sessionDownloadId
            };
            return vm.fileDownload;
        }

        function download() {
            if (vm.isProcessingDownload === true) {
                return;
            }
            vm.isProcessingDownload = true;

            setSessionDownload();
            resetIntervalLimitTime();
            startIntervalLimitTimeDownload();
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            vm.noPdf = false;
            vm.isDownloadingHasSentMail = false;
            var fileDownload = createFileDownload(fromDate, toDate, vm.sessionDownloadId);
            FileDownloadService.createSessionDownload(fileDownload).then(function (result) {
                onSaveSuccess(result, fromDate, toDate);
            }).catch(function (error) {
                onSaveError(error);
            });
        }

        function onSaveSuccess(result, fromDate, toDate) {
            var adhocReport = vm.isAdhoc;
            if (result) {
                vm.fileDownload.id = result.id;
                SurveyResultService.downloadHAQResultsReports(fromDate, toDate, adhocReport, vm.fileDownload.id).then(function (res) {
                    vm.isProcessing = false;
                    vm.isProcessingDownload = false;
                    var typeDownload = 'SEND_MAIL';
                    if (res.status === 204 && res.statusDownload && res.statusDownload === typeDownload) {
                        vm.isDownloadingHasSentMail = true;
                    } else if (res.status === 204) {
                        vm.noPdf = true;
                        vm.isDownloadingHasSentMail = false;
                    } else if (res.status === 200 && vm.hasOpenningDialogRequest) {
                        closeForceDialog();
                    }
                    stopIntervalLimitTimeDownload();
                }).catch(function (error) {
                    onSaveError(error);
                });
            }
        }

        function onSaveError(error) {
            vm.isProcessingDownload = false;
            stopIntervalLimitTimeDownload();
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };

        function checkProcessingDownload() {
            return vm.isProcessingDownload === true || vm.isProcessing === true;
        }

        function requestSendMailDownloadHAQ(id) {
            vm.isProcessing = true;
            stopIntervalLimitTimeDownload();
            var data = {
                typeDownload: 'SEND_MAIL'
            };
            FileDownloadService.updateTypeDownloadFileHAQ(id, data).then(function (res) {
                vm.isProcessing = false;
                if (res && res.status !== 200) {
                    console.warn('The download process has finished, but the email update was not successful.');
                }
            });

        }

        function checkAndOpenDialogRequestDownloadHAQ() {
            if (vm.fileDownload && vm.fileDownload.id) {
                FileDownloadService.getDownloadStatusBy(vm.fileDownload.id).then(function (res) {
                    if (res && res.status && res.status === 'GENERATING') {
                        openDialogRequestDownloadHAQ();
                        startIntervalCheckStatusDownload();
                    } else { // create pdf completed, not update request send mail
                        stopIntervalLimitTimeDownload();
                    }
                });
            }
        }

        function openDialogRequestDownloadHAQ() {
            vm.hasOpenningDialogRequest = true;
            $uibModal.open({
                templateUrl: 'app/shared/confirm-download-dialog/confirm-download-dialog.controller.html',
                controller: 'ConfirmDownloadController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                backdrop: 'static',
                size: 'sm'
            }).result.then(function (result) {
                if (result) { // Yes
                    requestSendMailDownloadHAQ(vm.fileDownload.id);
                }
                // case No
                stopIntervalLimitTimeDownload();
                stopIntervalCheckStatusDownload();
            });
        }

        function resetIntervalLimitTime() {
            vm.limitTimeStart = moment();
            vm.hasOpenningDialogRequest = false;
        }

        function stopIntervalLimitTimeDownload() {
            if (intervalLimitTimeDownload) {
                clearInterval(intervalLimitTimeDownload);
                $interval.cancel(intervalLimitTimeDownload);
            }
        }

        function startIntervalLimitTimeDownload() {
            intervalLimitTimeDownload = setInterval(function () {
                runIntervalLimitTimeDownload();
            }, 1000); // 1 seconds
        }
        function stopIntervalCheckStatusDownload() {
            if (intervalCheckStatusDownload) {
                clearInterval(intervalCheckStatusDownload);
                $interval.cancel(intervalCheckStatusDownload);
            }
        }

        function startIntervalCheckStatusDownload() {
            intervalCheckStatusDownload = setInterval(function () {
                runIntervalCheckStatusDownload();
            }, vm.limitTimeRequestCheckStatus); // 30 seconds
        }

        function runIntervalLimitTimeDownload() {
            var millisDiff = moment().diff(vm.limitTimeStart);
            if (millisDiff > vm.limitTimeMillisSecondsDownload) {
                var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
                var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
                if (!vm.hasOpenningDialogRequest) {
                    checkAndOpenDialogRequestDownloadHAQ();
                }
                // stop interval
                stopIntervalLimitTimeDownload();
            }
        }

        function runIntervalCheckStatusDownload(){
            FileDownloadService.getDownloadStatusBy(vm.fileDownload.id).then(function (res) {
                if (res && res.status && res.status !== 'GENERATING') {
                    closeForceDialog();
                    stopIntervalCheckStatusDownload(); // stop interval
                }
            });
        }

        function closeForceDialog() {
            $uibModalStack.dismissAll();
        }

        $scope.$on('$destroy', function () {
            stopIntervalLimitTimeDownload();
        });
    }
})();
