(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$filter', 'Principal', 'LoginService', 'Auth', '$rootScope', '$state', 'ipmConstants', 'SurveyResultService', 'NPQQuestionnaireService', 'supportedDataList', '$window', 'tmhDynamicLocale', '$localStorage', '$uibModal', 'ClearLocalStorageService', 'AgreementsWorkflowService', '$sessionStorage'];

    function HomeController ($scope, $filter, Principal, LoginService, Auth, $rootScope, $state, constants, SurveyResultService, NPQQuestionnaireService, supportedDataList, $window, tmhDynamicLocale, $localStorage, $uibModal, ClearLocalStorageService, AgreementsWorkflowService, $sessionStorage) {
    	var vm = this;

    	vm.isInActive = null;
        vm.isWorflowComplete = null;
        vm.account = null;
        vm.isAuthenticated = $window.localStorage.getItem("invitationWorkflow") ? true : false;
        vm.sessionTimeOut = null;
        vm.emailConfirmed = null;
        vm.errorEmailExists = null;
        vm.noWait = false;
        vm.isQuestionnaireIncomplete = null;
        vm.isQuestionnaireCount = null;
        vm.byPassRestriction = false;
        vm.loginProcess = true; // used to disable login form
        vm.isAdHocHaq = false;
        vm.isProcessingLogin = false;
    	// home page will redirect to main page after login to don't show login form
        forwardHomePage();

        // load offices, providers
        vm.offices = supportedDataList.officeList;
        vm.providers = supportedDataList.providerList;

        vm.loginAzure = loginAzure;
        vm.login = login;
        vm.username = null;
        vm.password = null;
        vm.rememberMe = false;
        vm.authenticationError = false;
        vm.azureLogoutProcessing = false;
        vm.isAzurePatientLogin = $window.localStorage.getItem("azurePatient") ? true : false;

        vm.confirmEmailAndInjuredType = confirmEmailAndInjuredType;
        vm.selectedInjuredTypes = selectedInjuredTypes;
        vm.patientInjuredTypes = [];
        vm.injuredTypes = [
            { name: "register.form.injured.legsfeet", value: "LEGS_FEET", checked: false, disabled: false },
            { name: "register.form.injured.backspine", value: "BACK_SPINE", checked: false, disabled: false },
            { name: "register.form.injured.neck", value: "NECK", checked: false, disabled: false },
            { name: "register.form.injured.armhand", value: "ARM_HAND", checked: false, disabled: false },
            { name: "register.form.injured.none", value: "NONE", checked: false, disabled: false }
         ];

        vm.agreement = null; // used to open Agreement Workflow

        function selectedInjuredTypes(injuredType) {
        	if(injuredType.value === 'NONE') {
        		if(injuredType.checked == true) {
        			vm.patientInjuredTypes = [injuredType.value];
       			 	vm.injuredTypes.forEach(function(item) {
       			 		if(item.value !== 'NONE') {
       			 			item.checked = false;
       			 			item.disabled = true;
       			 		}
                    });
       		 	} else {
       		 		vm.patientInjuredTypes = [];
       		 		vm.injuredTypes.forEach(function(item) {
       		 			if(item.value !== 'NONE') {
       		 				item.disabled = false;
       		 			}
                    });
       		 	}
   		 	} else {
   		 		vm.patientInjuredTypes = $filter('filter')(vm.injuredTypes, {checked: true}).map(function(item) {
   		 			return item.value;
   		 		});
   		 		vm.injuredTypes.forEach(function(item) {
   		 			if(item.value === 'NONE') {
   		 				item.disabled = vm.patientInjuredTypes.length > 0;
   		 			}
   		 		});
   		 	}
        }

        $rootScope.$on("sessionTimeOut", function(event, args){
            vm.sessionTimeOut = true;
            vm.isInActive = null;
            vm.isWorflowComplete = null;
            vm.emailConfirmed = null;
            vm.isAdHocHaq = false;
            vm.isQuestionnaireIncomplete = null;
            vm.isQuestionnaireCount = null;
        });

        if($rootScope.sessionTimeOut) {
            vm.sessionTimeOut = true;
            $rootScope.sessionTimeOut = null;
        }

        vm.requestResetPassword = requestResetPassword;

        $scope.$on(constants.EVT_LOG_OUT, function() {
            $window.localStorage.removeItem("noWait");
            clearPassRestriction();
            clearLoginProcess();
            vm.noWait = false;
            vm.sessionTimeOut = null;
        	vm.isInActive = null;
            vm.isWorflowComplete = null;
            vm.emailConfirmed = null;
            vm.isAdHocHaq = false;
            vm.isAuthenticated = null;
            vm.username = null;
            vm.password = null;
            vm.isQuestionnaireIncomplete = null;
            vm.isQuestionnaireCount = null;
            ClearLocalStorageService.clearLandingPage();
            ClearLocalStorageService.clearKeepLandingPage();
            ClearLocalStorageService.clearTimeSpent();
            ClearLocalStorageService.clearTimeSpent();
        });

        // keep old logic Login
        function processAfterLogin() {
            vm.isWorflowComplete = false;
            vm.isInActive = false;
            vm.authenticationError = false;
            vm.isAuthenticated = true;
            // If we're redirected to login, our
            // previousState is already set in the authExpiredInterceptor. When login succesful go to stored state
            /*if ($rootScope.redirected && $rootScope.previousStateName) {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);

            } else {}*/
            $rootScope.$broadcast(constants.EVT_AUTHENTICATION_SUCCESS);
            if (Principal.hasAnyAuthority([constants.ROLE_ADMIN]) === true) {
                if (checkDownloadPreviousState()) {
                    goToPreviousState();
                } else {
                    $state.go('user-management');
                }
            } else if (Principal.hasAnyAuthority([constants.ROLE_BILLER]) === true) {
                if (checkDownloadPreviousState()) {
                    goToPreviousState();
                } else {
                    $state.go('patient-list');
                }
            } else if (Principal.hasAnyAuthority([constants.ROLE_STAFF]) === true || Principal.hasAnyAuthority([constants.ROLE_MANAGER]) === true) {
                $state.go('survey-result-list');
            } else if (Principal.hasAnyAuthority([constants.ROLE_PATIENT]) === true) {
                // $state.go('npq-questionnaire.workflow');
                forwardToCurrentWorkflow();
            } else if (Principal.hasAnyAuthority([constants.ROLE_QHP]) === true) {
                goToHAQEvaluationWorkflow();
            } else {
                vm.authenticationError = true;
            }
            $rootScope.redirected = false;
        }

        function goToPreviousState() {
            if ($rootScope && $rootScope.previousStateName && $rootScope.previousStateName.name) {
                $state.go($rootScope.previousStateName.name, $rootScope.previousStateNameParams);
                return;
            }
        }

        function checkDownloadPreviousState() {
            return $rootScope.previousStateName && $rootScope.previousStateName.name
                && ($rootScope.previousStateName.name === 'download-haq-activity-report.requested' || $rootScope.previousStateName.name === 'download-haq-results-reports.requested');
        }

        function clearAzurePatient() {
            $window.localStorage.removeItem("azurePatient");
            vm.isAzurePatientLogin = false;
        }

        function loginAzure(event) {
            vm.isProcessingLogin = true;
            event.preventDefault();
            clearAzurePatient();
            clearPassRestriction();
            clearLoginProcess();
            vm.sessionTimeOut = null;
            $state.go('samlLogin', null, {reload: true});
            return;
        }
        
        function login (event) {
            event.preventDefault();
            clearAzurePatient();
            clearPassRestriction();
            clearLoginProcess();
            vm.sessionTimeOut = null;
            if(vm.username != null && vm.username.indexOf(constants.NO_WAIT, vm.username.length - constants.NO_WAIT.length) !== -1) {
                vm.username = vm.username.substring(0, vm.username.lastIndexOf("+"));
                vm.noWait = true;
            }

            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function (res) {
                processAfterLogin();
            }).catch(function () {
                vm.authenticationError = true;
            });
            if(vm.authenticationError && vm.noWait) {
                vm.noWait = false;
                vm.username = vm.username.concat(constants.NO_WAIT);
            }
        }

        function confirmEmailAndInjuredType() {
            vm.isSaving = true;
            var tempEmail = encodeURIComponent(vm.email);
            Auth.confirmEmailAndInjuredTypes(tempEmail, vm.patientInjuredTypes).then(function(response){
                vm.isSaving = false;
                if(response.data === 'e-mail address already in use'){
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.errorEmailExists = null;
                    if(response.status === 200) {
                        if (vm.byPassRestriction && $window.localStorage.getItem(constants.SURVEY_RESULT_ID)) {
                            getCurrentSurvey();
                        } else if ($window.localStorage.getItem(constants.SURVEY_RESULT_ID)) {
                            forwardToDirectLinkWorkflow();
                        } else {
                            forwardToCurrentWorkflow();
                        }
                    }
                }
            });
        }

        $scope.$watch('vm.email', function() {
            vm.errorEmailExists = null;
        });

        function requestResetPassword () {
            $state.go('requestReset');
        }

        $scope.$on(constants.EVT_AUTHENTICATION_SUCCESS, function() {
            getAccount(false);
        });

        function getAccount(force) {
            Principal.identity(force).then(function(account) {
                vm.account = account;
                vm.isAuthenticated = $window.localStorage.getItem("invitationWorkflow") ? true : Principal.isAuthenticated;
                if(account && account.langKey !== tmhDynamicLocale.get()) {
                    $rootScope.$emit("changeLanguage", {langKey: account.langKey});
                }
            });

        }

        function forwardHomePage() {
            vm.isWorflowComplete = false;
        	vm.isInActive = false;
            if ($window.localStorage.getItem(constants.SURVEY_RESULT_ID) || $window.localStorage.getItem(constants.NPQ_QUESTIONNAIRE_ID)) {
                forwardToDirectLinkWorkflow();
            } else {
                Principal.identity(false).then(function (account) {
                    if (!account) {
                        // clear all local storage
                        ClearLocalStorageService.clearAll();
                        ClearLocalStorageService.clearLandingPage();
                        ClearLocalStorageService.clearKeepLandingPage();
                        $sessionStorage.currentSurveyResultId = null;
                        clearLoginProcess();
                    }
                    if (Principal.hasAnyAuthority([constants.ROLE_PATIENT]) === true && account.azureLogoutUrl !== '') {
                    	vm.azureLogoutProcessing = true;
                    	// Patient login azure
                    	$window.localStorage.setItem("azurePatient", true);
                    	vm.isAzurePatientLogin = true;
                    	Auth.logout();
			            $state.go('home');
			            ClearLocalStorageService.clearAfterLogout();
			            $rootScope.$broadcast(constants.EVT_LOG_OUT);
			            $window.location.href = account.azureLogoutUrl;
                    }
                    if (Principal.hasAnyAuthority([constants.ROLE_ADMIN]) === true) {
                        $state.go('user-management');
                    } else if (Principal.hasAnyAuthority([constants.ROLE_BILLER]) === true) {
                        $state.go('patient-list');
                    } else if (Principal.hasAnyAuthority([constants.ROLE_STAFF]) === true || Principal.hasAnyAuthority([constants.ROLE_MANAGER]) === true) {
                        $state.go('survey-result-list');
                    } else if (Principal.hasAnyAuthority([constants.ROLE_PATIENT]) === true) {
                        if (checkLandingPage()) {
                            return;
                        }
                        forwardToCurrentWorkflow();

                    } else if (Principal.hasAnyAuthority([constants.ROLE_QHP]) === true) {
                        goToHAQEvaluationWorkflow();
                    }
                });
            }
        }

        function forwardToDirectLinkWorkflow() {
            // this block code using for invitation work flow [ reload this route when user logged]
            if ($window.localStorage.getItem(constants.SURVEY_RESULT_ID) || $window.localStorage.getItem(constants.NPQ_QUESTIONNAIRE_ID)) {
                vm.loginProcess = true; // start disable Login
                var _passRestriction = $window.localStorage.getItem(constants.PASS_RESTRICTION);
                if (_passRestriction && true == Boolean(_passRestriction)) {
                    vm.byPassRestriction = true;
                }

                Principal.identity(true).then(function (account) {
                    vm.account = account;
                    vm.isWorflowComplete = false;
                    vm.isInActive = false;
                    vm.authenticationError = false;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    $rootScope.$broadcast(constants.EVT_AUTHENTICATION_SUCCESS);
                    if (account && account.langKey !== tmhDynamicLocale.get()) {
                        $rootScope.$emit("changeLanguage", {langKey: account.langKey});
                    }

                    if (Principal.hasAnyAuthority([constants.ROLE_PATIENT]) === true) {
                        if (checkLandingPage()) {
                            return;
                        }
                        if ($window.localStorage.getItem("surveyResultId")) {
                            getCurrentSurvey();
                        } else {
                            AgreementsWorkflowService.getCurrentAgreementWorkflow(null).then(function (res) {
                                setAgreement(res);
                                getTheMostRecentNPQQuestionnaire();
                            });
                        }
                    }
                    if (!vm.account) {
                        // clear all local storage
                        ClearLocalStorageService.clearAll();
                        ClearLocalStorageService.clearLandingPage();
                        ClearLocalStorageService.clearKeepLandingPage();
                        $sessionStorage.currentSurveyResultId = null;
                        clearLoginProcess();
                    }
                });

            }
        }

        function forwardToCurrentWorkflow() {
            if (checkLandingPage()) {
                return;
            }
            AgreementsWorkflowService.getCurrentAgreementWorkflow(null).then(function (res) {
                setAgreement(res);

                if ($localStorage.isQuestionnaireCount === true) {
                    goToNPQWorkflow();
                    return;
                } else if ($localStorage.isQuestionnaireCount === false) {
                    getCurrentSurvey();
                    return;
                }
                NPQQuestionnaireService.checkingStatusPatient().then(function (res) {
                    if (res) {
                        if (res.isFullyComplete) {
                            getCurrentSurvey();
                        } else if (checkAgreementsWorkflow()) {
                            return;
                        } else if (res.isOpenCountDialog) {
                            vm.isQuestionnaireCount = true;
                            openQuestionnaireCountDialog(res.numberOfNpqNotYetComplete, null);
                        } else if (res.isOpenResumeDialog) {
                            vm.isQuestionnaireIncomplete = true;
                            openQuestionnaireResumeDialog();
                        } else {
                            goToNPQWorkflow();
                        }
                    } else {
                        if (
                            $localStorage.isQuestionnaireIncomplete === true
                        ) {
                            goToNPQWorkflow();
                        } else if (
                            $localStorage.isQuestionnaireIncomplete === false
                        ) {
                            getCurrentSurvey();
                        }
                    }
                });
            });
        }

        function getTheMostRecentNPQQuestionnaire() {
            // if local storage have npqQuestionairId we will get by id, else we get by most recent
            var npqQuestionnaireId = $window.localStorage.getItem(constants.NPQ_QUESTIONNAIRE_ID);
            NPQQuestionnaireService.getTheMostRecentNPQQuestionnaireOrById(npqQuestionnaireId).then(function(res) {
                if (res) {
                    goToNPQWorkflow();
                } else {
                    if ($window.localStorage.getItem("noWait")) {
                        vm.noWait = true;
                    }
                    getCurrentSurvey();
                }
            }).catch(function (error) {
                if ($window.localStorage.getItem("noWait")) {
                    vm.noWait = true;
                }
                getCurrentSurvey();
            });
        }

        function openQuestionnaireResumeDialog() {
            $uibModal.open({
                templateUrl: 'app/home/questionnaire-resume-dialog.html',
                controller: 'QuestionnaireResumeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowClass: 'absolute-center vertical-center'
            }).result.then(function(result) {
                if (result && result === true) {
                    // case yes
                    $localStorage.isQuestionnaireIncomplete = true;
                    goToNPQWorkflow();
                } else {
                    // case no
                   // getCurrentSurvey();
                    clearAndDirectToLandingPage();
                }
            }, function() {
                // case escape key
                // getCurrentSurvey();
                clearAndDirectToLandingPage();
            });
        }

        function openQuestionnaireCountDialog(countQuestionnaire, id) {
            $uibModal.open({
                templateUrl: 'app/home/questionnaire-count-dialog.html',
                controller: 'QuestionnaireCountDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    countQuestionnaire: function() {
                        return countQuestionnaire;
                    }
                }
            }).result.then(function(result) {
                if (result && result === true) {
                    // case yes
                    $localStorage.firstIdOfAllNPQQuestionnaireNotComplete = id;
                    $localStorage.isQuestionnaireCount = true;
                    vm.isQuestionnaireCount = false;
                    goToNPQWorkflow();
                } else {
                    // case no
                    // getCurrentSurvey();
                    clearAndDirectToLandingPage();
                }
            }, function() {
                // case escape key
                // getCurrentSurvey();
                clearAndDirectToLandingPage();
            });
        }

        function getCurrentSurvey() {
            // if local storage have surveyResultId we will get by id, else we get by most recent
            var surveyResultId = $window.localStorage.getItem("surveyResultId");
            SurveyResultService.getCurrentSurveyResult(vm.noWait, surveyResultId).then(function(res) {
                vm.isAdHocHaq = res ? res.isAdHoc : false;
                if((res.allowStartNewBattery === null || res.allowStartNewBattery === true || vm.byPassRestriction) && res.isEmailConfirmed === false && res.disposition === true && !vm.isAdHocHaq) {
                    if(vm.noWait) {
                        $window.localStorage.setItem("noWait", true);
                    }
                    Principal.identity(false).then(function(account) {
                        vm.account = account;
                        vm.emailConfirmed = false;
                        vm.email = account.email === null ? account.email : account.email.trim();
                        vm.hasEmail = vm.email;
                        vm.injuredType = account.injuredType;
                        vm.patientInjuredTypes = account.injuredTypes ? account.injuredTypes : [];
                        vm.injuredTypes.forEach(function(item) {
                            item.checked = vm.patientInjuredTypes.indexOf(item.value) !== -1;
                            if(vm.patientInjuredTypes[0] && vm.patientInjuredTypes[0] === 'NONE') {
                                if(item.value !== 'NONE') {
                                    item.disabled = true;
                                }
                            } else {
                                if(vm.patientInjuredTypes.length > 0) {
                                    if(item.value === 'NONE') {
                                        item.disabled = true;
                                    }
                                }
                            }
                        });
                    });
                } else {
                    $window.localStorage.removeItem("noWait");
                    if(res.disposition == true) {
                        if(res.status !== 'COMPLETE' && res.status !== 'INCOMPLETE' && res.status !== 'NO_RESULT' && (res.allowStartNewBattery == null || res.allowStartNewBattery == true || vm.byPassRestriction)) {
                            // forward to survey workflow
                            if (res.goToCourseWorkflow) { 
                                $state.go('course-registration-workflow');
                            } else {
                                $state.go('survey-workflow',{id: res.batteryTestId});
                            }
                        } else {
                            vm.isWorflowComplete = true;
                        }
                    } else {
                        vm.isInActive = true;
                    }
                }
                $localStorage.isQuestionnaireIncomplete = false;
                $localStorage.isQuestionnaireCount = false;
                vm.isQuestionnaireIncomplete = false;
                vm.isQuestionnaireCount = false;
            });
        }
        vm.getCurrentSurvey = getCurrentSurvey;

        function clearPassRestriction() {
            vm.byPassRestriction = false;
        }

        function clearLoginProcess() {
            vm.loginProcess = false;
        }


        function checkAgreementsWorkflow() {
            if (vm.agreement && !vm.agreement.isFinishAllAgreement) {
                goToAgreementsWorkflow();
                return true;
            }
            return false;
        }

        function goToAgreementsWorkflow() {
            $state.go('agreements-workflow');
        }

        function setAgreement(response) {
            vm.agreement = response ? response : null;
        }

        function goToNPQWorkflow() {
            // Check AgreementWorkflow before open NPQ Workflow, if AgreementWorkflow Not Complete, system will open Agreement Workflow
            if (checkAgreementsWorkflow()) {
                return;
            }
            $state.go('npq-questionnaire.workflow');
        }

        function goToLandingPage() {
            $state.go('landing-page');
        }

        function checkLandingPage() {
            // landing page is null or false will open landing page
            var _landingPage = ClearLocalStorageService.getLandingPage();
            if (!_landingPage || !Boolean(_landingPage)) {
                goToLandingPage();
                return true;
            }
        }

        function clearAndDirectToLandingPage() {
            // allow open Resume NPQ next open
            vm.isQuestionnaireCount = null;
            vm.isQuestionnaireIncomplete = null;
            ClearLocalStorageService.clearAllAndWorkflow();
            goToLandingPage();
            return true;
        }

        function goToHAQEvaluationWorkflow() {
            Principal.identity(true).then(function (account) {
                vm.account = account;
                if (vm.account && vm.account.qhpUserDetail && vm.account.qhpUserDetail.tasks) {
                    var tasks = vm.account.qhpUserDetail.tasks;
                    if (tasks && tasks.length > 1) {
                        $state.go('qhp-workflow-landing-page');
                        return;
                    } else if (tasks && tasks[0] === constants.HAQ_EVALUATION) {
                        $state.go('haq-evaluation');
                        return;
                    } else if (tasks && tasks[0] === constants.CARE_PLAN_REVIEW) {
                        $state.go('care-plan-review');
                        return;
                    } else if (tasks && tasks[0] === constants.FRP_MDE) {
                        $state.go('frp-mde');
                        return;
                    }else if (tasks && tasks[0] === constants.INSTRUCTOR) {
                        $state.go('wellness-class-list');
                        return;
                    } else if (tasks && tasks[0] === constants.HAQ_BRIEF_REVIEW) {
                        $state.go('haq-brief-review');
                        return;
                    }
                }
            });
        }
    }
})();
