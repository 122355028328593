(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('WebWorkerEvlService', WebWorkerEvlService);
    WebWorkerEvlService.$inject = ['ipmConstants'];

    function WebWorkerEvlService(constants) {
        var service = {
            'close': close,
            'getWebWorker': getWebWorker,
            'startTimeSpent': startTimeSpent,
            'resetTimeSpent': resetTimeSpent,
            'clearTimeSpent': clearTimeSpent,
            'startIntervalAutoSave': startIntervalAutoSave,
            'resetIntervalAutoSave': resetIntervalAutoSave,
            'clearIntervalAutoSave': clearIntervalAutoSave
        };

        var webWorker = null;
        var versionWorker = 'v1';
        var urlWorker = 'content/js/workers/eval-worker-' + versionWorker + '.js'; // we will change the version from eval-worker-v1.js to clear the cache

        // Function to terminate and clean up the web worker
        function close() {
            if (webWorker) {
                sendMessageToWorker(constants.WEB_WORKER.CLOSE);
                webWorker.terminate();
                webWorker = null;
            }
        }

        // Function to start the time spent interval
        function startTimeSpent(force) {
            if (force || !webWorker) {
                initializeWorker();
            }
            sendMessageToWorker(constants.WEB_WORKER.START_TIME_SPENT_INTERVAL_EVL);
            return webWorker;
        }

        // Function to reset the time spent interval
        function resetTimeSpent() {
            sendMessageToWorker(constants.WEB_WORKER.RESET_TIME_SPENT_INTERVAL_EVL);
        }

        // Function to clear the time spent interval
        function clearTimeSpent() {
            sendMessageToWorker(constants.WEB_WORKER.CLEAR_TIME_SPENT_INTERVAL_EVL);
        }

        // Function to start the auto-save interval
        function startIntervalAutoSave() {
            if (!webWorker) {
                initializeWorker();
            }
            sendMessageToWorker(constants.WEB_WORKER.START_AUTO_SAVE_INTERVAL_EVL);
            return webWorker;
        }

        // Function to clear the auto-save interval
        function clearIntervalAutoSave() {
            sendMessageToWorker(constants.WEB_WORKER.CLEAR_AUTO_SAVE_INTERVAL_EVL);
        }

        // Function to reset the auto-save interval
        function resetIntervalAutoSave() {
            sendMessageToWorker(constants.WEB_WORKER.RESET_AUTO_SAVE_INTERVAL_EVL);
        }

        // Helper function to get the current web worker instance
        function getWebWorker() {
            return webWorker;
        }

        // Helper function to initialize the web worker
        function initializeWorker() {
            close(); // Clean up any existing worker before creating a new one
            webWorker = new Worker(urlWorker);
        }

        // Helper function to send messages to the web worker safely
        function sendMessageToWorker(messageType) {
            if (webWorker) {
                webWorker.postMessage({type: messageType});
            } else {
                console.warn('WebWorker is not initialized.');
            }
        }

        return service;
    }
})();
