(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('FileDownloadService', FileDownloadService);

    FileDownloadService.$inject = ['$resource','$q', 'halClient', '$http', 'DownloadBlobService'];

    function FileDownloadService($resource, $q, halClient, $http, DownloadBlobService) {
        var baseUrl = 'api/file-downloads';
        var service = {
            'createSessionDownload': createSessionDownload,
            'updateTypeDownloadFileHAQ': updateTypeDownloadFileHAQ,
            'getFileDownloadBySecretKey': getFileDownloadBySecretKey,
            'downloadReport': downloadReport,
            'getDownloadStatusBy': getDownloadStatusBy

        };

        function createSessionDownload(fileDownload) {
            return $http.post(baseUrl, fileDownload).then(function (resp) {
                return resp.data;
            });
        }

        function updateTypeDownloadFileHAQ(id, paramDownload) {
            var url = baseUrl + '/update-type-download-file-haq/' + id;

            return $http.post(url, paramDownload
            ).then(function (resp) {
                return resp;
            });
        }

        function getDownloadStatusBy(id) {
            var url = baseUrl + '/get-download-status-by/' + id;
            return $http.get(url).then(function (resp) {
                return resp.data;
            });
        }

        function getFileDownloadBySecretKey(secretKey) {
            var url = baseUrl + '/get-file-by-secret-key/' + secretKey;
            return $http.get(url).then(function (resp) {
                return resp;
            });
        }

        function downloadReport(secretKey) {
            var deferred = $q.defer();
            var url = baseUrl + '/download-report/' + secretKey;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        return service;

    }
})();
